<template>
  <div class="profile">
    <div class="main_container">
      <div class="profile_in">
        <div class="personal_cabinet">
          <div class="head">
            <div class="title">Личный кабинет</div>
            <img src="@/assets/images/pen.svg" alt="pen" />
          </div>
          <div class="user">
            <div class="avatar">
              <img src="@/assets/images/avatar.png" alt="avatar" />
            </div>
            <div class="user_info">
              <div class="role">Admin</div>
              <div class="role_val">ddr</div>
            </div>
          </div>
        </div>
        <div class="user_info_body">
          <div class="body_item">
            <div class="body_item_wrapper">
              <span>
                <svg
                  width="21"
                  height="21"
                  viewBox="0 0 21 21"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M10.5 14V10.5M10.5 7H10.5087M19.25 10.5C19.25 15.3325 15.3325 19.25 10.5 19.25C5.66751 19.25 1.75 15.3325 1.75 10.5C1.75 5.66751 5.66751 1.75 10.5 1.75C15.3325 1.75 19.25 5.66751 19.25 10.5Z"
                    stroke="#1E1E1E"
                    stroke-width="1.96875"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </span>
              <div class="body_item_right_block">
                <div class="body_item_head">Тариф</div>
                <div class="body_item_val">SellerTop до 28.10.24</div>
              </div>
            </div>
            <div class="body_item_wrapper">
              <span>
                <svg
                  width="21"
                  height="21"
                  viewBox="0 0 21 21"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M10.5 14V10.5M10.5 7H10.5087M19.25 10.5C19.25 15.3325 15.3325 19.25 10.5 19.25C5.66751 19.25 1.75 15.3325 1.75 10.5C1.75 5.66751 5.66751 1.75 10.5 1.75C15.3325 1.75 19.25 5.66751 19.25 10.5Z"
                    stroke="#1E1E1E"
                    stroke-width="1.96875"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </span>
              <div class="body_item_right_block">
                <div class="body_item_head">Организации</div>
                <div class="body_item_val">5 шт.</div>
              </div>
            </div>
            <button class="item_btn">Сменить тариф</button>
          </div>
          <div class="body_item">
            <div class="body_item_wrapper">
              <span>
                <svg
                  width="21"
                  height="21"
                  viewBox="0 0 21 21"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M10.5 14V10.5M10.5 7H10.5087M19.25 10.5C19.25 15.3325 15.3325 19.25 10.5 19.25C5.66751 19.25 1.75 15.3325 1.75 10.5C1.75 5.66751 5.66751 1.75 10.5 1.75C15.3325 1.75 19.25 5.66751 19.25 10.5Z"
                    stroke="#1E1E1E"
                    stroke-width="1.96875"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </span>
              <div class="body_item_right_block">
                <div class="body_item_head">Баланс</div>
                <div class="body_item_val">16 950 ₽</div>
              </div>
            </div>
            <div class="body_item_wrapper">
              <span>
                <svg
                  width="21"
                  height="21"
                  viewBox="0 0 21 21"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M10.5 14V10.5M10.5 7H10.5087M19.25 10.5C19.25 15.3325 15.3325 19.25 10.5 19.25C5.66751 19.25 1.75 15.3325 1.75 10.5C1.75 5.66751 5.66751 1.75 10.5 1.75C15.3325 1.75 19.25 5.66751 19.25 10.5Z"
                    stroke="#1E1E1E"
                    stroke-width="1.96875"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </span>
              <div class="body_item_right_block">
                <div class="body_item_head">Каналы продаж</div>
                <div class="body_item_val">16 шт.</div>
              </div>
            </div>
            <button class="item_btn">Пополнить баланс</button>
          </div>
          <div class="body_item">
            <div class="body_item_wrapper">
              <span>
                <svg
                  width="21"
                  height="21"
                  viewBox="0 0 21 21"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M10.5 14V10.5M10.5 7H10.5087M19.25 10.5C19.25 15.3325 15.3325 19.25 10.5 19.25C5.66751 19.25 1.75 15.3325 1.75 10.5C1.75 5.66751 5.66751 1.75 10.5 1.75C15.3325 1.75 19.25 5.66751 19.25 10.5Z"
                    stroke="#1E1E1E"
                    stroke-width="1.96875"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </span>
              <div class="body_item_right_block">
                <div class="body_item_head">Маркетплейсы</div>
                <div class="body_item_val">4 шт.</div>
              </div>
            </div>
            <div class="body_item_wrapper">
              <span>
                <svg
                  width="21"
                  height="21"
                  viewBox="0 0 21 21"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M10.5 14V10.5M10.5 7H10.5087M19.25 10.5C19.25 15.3325 15.3325 19.25 10.5 19.25C5.66751 19.25 1.75 15.3325 1.75 10.5C1.75 5.66751 5.66751 1.75 10.5 1.75C15.3325 1.75 19.25 5.66751 19.25 10.5Z"
                    stroke="#1E1E1E"
                    stroke-width="1.96875"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </span>
              <div class="body_item_right_block">
                <div class="body_item_head">Товары</div>
                <div class="body_item_val">1350 шт.</div>
              </div>
            </div>
            <button class="item_btn">Перейти в админ-панель</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  computed: {
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    },
  },
  mounted() {
    if (!this.loggedIn) {
      this.$router.push("/");
    }
  },
};
</script>
<style lang="scss" scoped>
.profile {
  padding: 133px 0 121px;
  min-height: calc(100vh - 429px);
  .profile_in {
    display: flex;
    align-items: stretch;
    gap: 90px;
    .personal_cabinet {
      max-width: 295px;
      width: 100%;
      box-shadow: 6px 2px 20px 0 rgba(0, 0, 0, 0.3);
      background: #fff;
      border-radius: 20px;
      padding: 27px 25px 25px;
      .head {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-bottom: 19px;
        border-bottom: 1px solid #a9a9a9;
        margin-bottom: 35px;
        .title {
          font-family: var(--third-family);
          font-weight: 600;
          font-size: 16px;
          color: #000;
        }
      }
      .user {
        display: flex;
        align-items: center;
        gap: 18px;
        .avatar {
          width: 74px;
          height: 74px;
          border-radius: 100%;
          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }
        &_info {
          .role {
            font-family: var(--third-family);
            font-weight: 600;
            font-size: 14px;
            color: #000;
          }
          .role_val {
            font-family: var(--third-family);
            font-weight: 600;
            font-size: 12px;
            color: #a9a9a9;
          }
        }
      }
    }
    .user_info_body {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      width: 100%;
      .body_item {
        .body_item_wrapper {
          display: flex;
          gap: 16px;
          margin-bottom: 41px;
          .body_item_head {
            font-family: var(--third-family);
            font-weight: 600;
            font-size: 16px;
            color: #000;
            white-space: nowrap;
            margin-bottom: 9px;
          }
          .body_item_val {
            font-family: var(--second-family);
            font-weight: 500;
            font-size: 12px;
            white-space: nowrap;
            color: #a9a9a9;
          }
        }
        .item_btn {
          background: #ef6c1e;
          border-radius: 15px;
          padding: 10px 20px;
          font-family: var(--font4);
          font-weight: 600;
          font-size: 14px;
          line-height: 17px;
          color: #fff;
        }
      }
    }
  }
}
@media (max-width: 992px) {
  .profile {
    padding: 100px 0;
    .profile_in {
      flex-direction: column;
      align-items: center;
      .personal_cabinet {
        max-width: 500px;
      }
      .user_info_body {
        column-gap: 15px;
        row-gap: 35px;
      }
    }
  }
}
@media (max-width: 576px) {
  .profile {
    padding: 50px 0;
    .profile_in {
      gap: 35px;
      .personal_cabinet {
        padding: 20px;
      }
      .user_info_body {
        gap: 35px;
        .body_item {
          width: 100%;
          .body_item_wrapper {
            margin-bottom: 25px;
          }
          .item_btn {
            display: block;
            max-width: 320px;
            width: 100%;
          }
        }
      }
    }
  }
}
</style>
