<script>
export default {
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
};
</script>

<template>
  <div class="card_item">
    <div class="card_head">
      <!-- Название маркетплейса -->
      <div class="card_title">{{ item.name }}</div>
    </div>
    <div class="card_info">
      <!-- Изображение слева -->
      <div class="card_img">
        <img :src="item.imageUrl || 'https://via.placeholder.com/150'" alt="Marketplace Image" />
      </div>
      <!-- Текстовое описание справа -->
      <div class="card_body">
        <p class="card_description">{{ item.desc }}</p>
        <!-- Список особенностей (опционально) -->
        <ul class="card_body_list" v-if="item.features && item.features.length">
          <li class="list_item" v-for="(feature, index) in item.features" :key="index">{{ feature }}</li>
        </ul>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
/* Оформление карточки */
.card_item {
  display: flex;
  flex-direction: column;
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  background-color: #fff;
  margin-top: 35px;
  transition: transform 0.2s ease-in-out;
  &:hover {
    transform: translateY(-5px);
  }
}

/* Заголовок карточки */
.card_title {
  font-size: 24px;
  font-weight: bold;
  color: #333;
  margin-bottom: 15px;
  text-align: center;
}

/* Контейнер с изображением и текстом */
.card_info {
  display: flex;
  gap: 20px;
  align-items: center;
  @media (max-width: 768px) {
    flex-direction: column;
  }
}

/* Изображение */
.card_img {
  width: 150px;
  height: 150px;
  background-color: #f0f0f0;
  border-radius: 10px;
  overflow: hidden;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

/* Описание маркетплейса */
.card_body {
  flex: 1;
  .card_description {
    font-size: 14px;
    color: #555;
    margin-bottom: 10px;
    line-height: 1.5;
  }
  .card_body_list {
    margin-top: 15px;
    .list_item {
      font-size: 12px;
      color: #777;
    }
  }
}
</style>
