<script>
export default {
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
};
</script>

<template>
  <div class="tariff_card">
    <div class="tariff_header">
      <!-- Название тарифа -->
      <div class="tariff_title">{{ item.name }}</div>
      <!-- Цена -->
      <div class="tariff_price">
        <span>{{ item.price }}</span> Руб / месяц
      </div>
    </div>
    <!-- Описание тарифа -->
    <div class="tariff_body">
      <ul class="tariff_features">
        <li class="feature" v-for="(feature, index) in item.features" :key="index">
          {{ feature.key }} : {{ feature.value }}
        </li>
      </ul>
    </div>
  </div>
</template>

<style lang="scss" scoped>
/* Оформление карточки тарифа */
.tariff_card {
  display: flex;
  flex-direction: column;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0,0,0,.1);
  background-color: #fff;
  text-align: center;
  max-width: 300px;
  margin: 10px;
  transition: transform .2s ease-in-out;
}

/* Заголовок тарифа */
.tariff_header {
  margin-bottom: 20px;
  .tariff_title {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 10px;
  }
  .tariff_price {
    font-size: 30px;
    font-weight: bold;
    color: #000;
  }
}

/* Тело карточки с описанием */
.tariff_body {
  .tariff_features {
    list-style-type: none;
    padding: 0;
    margin: 0;
    .feature {
      font-size: 14px;
      margin-bottom: 10px;
    }
  }
}
</style>
