<script>
import Tarrif from "@/components/UI/Tarrif.vue";
import PageHead from "@/components/UI/PageHead.vue";
import { ref, onMounted } from "vue";

export default {
  components: {
    Tarrif,
    PageHead,
  },
  setup() {
    const data = ref(null);
    const error = ref(null);

    const fetchData = async () => {
      try {
        const response = await fetch("https://uniseller.io/api/tarrifs");
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        const result = await response.json();
        data.value = result;
      } catch (err) {
        error.value = err;
      }
    };

    onMounted(() => {
      fetchData();
    });

    return {
      data,
      error,
    };
  },
};
</script>

<template>
  <div class="tarrifs">
    <div class="main_container">
      <PageHead name="Услуги"/>
      <div class="tarrifs_card">
        <div v-if="data && data.tarrifs">
          <Tarrif v-for="item in data.tarrifs" :key="item.name" :item="item"/>
        </div>
        <div v-else-if="error">
          <p>Ошибка при загрузке данных: {{ error.message }}</p>
        </div>
        <div v-else>
          <p>Загрузка...</p>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.tarrifs {
  padding: 46px 0 72px;

  .tarrifs_card {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 46px;
    justify-content: space-around;

    /* Стили для первого дочернего элемента */
    & > *:first-child {
      /* Например, добавим рамку для первого элемента */
      display: flex;
    }
  }
}

@media (max-width: 576px) {
  .tarrifs {
    .tarrifs_card {
      flex-direction: column; /* Для маленьких экранов карточки снова будут располагаться вертикально */
      gap: 35px;
    }
  }
}

</style>
