<script>
import Card from "@/components/UI/Card.vue";
import PageHead from "@/components/UI/PageHead.vue";
import { ref, onMounted } from "vue";

export default {
  components: {
    Card,
    PageHead,
  },
  setup() {
    const data = ref(null);
    const error = ref(null);

    const fetchData = async () => {
      try {
        const response = await fetch("https://uniseller.io/api/integrations");
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        const result = await response.json();
        data.value = result;
      } catch (err) {
        error.value = err;
      }
    };

    onMounted(() => {
      fetchData();
    });

    return {
      data,
      error,
    };
  },
};
</script>

<template>
  <div class="integrations">
    <div class="main_container">
      <PageHead name="Интеграции"/>
      <div class="integrations_card">
        <div v-if="data && data.integrations">
          <Card v-for="item in data.integrations" :key="item.name" :item="item"/>
        </div>
        <div v-else-if="error">
          <p>Ошибка при загрузке данных: {{ error.message }}</p>
        </div>
        <div v-else>
          <p>Загрузка...</p>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.integrations {
  padding: 46px 0 72px;

  .integrations_card {
    display: flex;
    flex-direction: column;
    gap: 46px;
  }
}

@media (max-width: 576px) {
  .marketplaces {
    .integrations_card {
      gap: 35px;
    }
  }
}
</style>
